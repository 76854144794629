<template>
  <v-container id="farmaco-info" fluid>
    <!-- TÍTULO  -->
    <v-row class="justify-center">
      <v-col cols="12" md="9" align-self="center">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-dna</template>
          <template v-slot:toptitle-text>
            {{ geneData.symbol }} | {{ geneData.name }}</template
          >
        </page-top-title>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="geneData.id != null">
      <v-col cols="8" md="8" lg="8">
        <v-content class="fill-height gradient-bucle">
          <section key="basic_info" id="basic_info">
            <v-card class="pa-0" rounded="lg">
              <v-card-title class="solid-heading rounded-lg py-3">
                <div class="card-title white--text mb-n2">
                  1. {{ $t("wgpgx_basic_info") }}
                </div>
              </v-card-title>
              <v-card-text>
                <div class="basic-info">
                  <strong class="px-2">{{$t('wgpgx_symbol')}}:</strong>
                  <vue-markdown :source="geneData['symbol']" />
                </div>
                <div class="basic-info">
                  <strong class="px-2">{{$t('wgpgx_name')}}:</strong>
                  <vue-markdown :source="geneData['name']" />
                </div>
                <div class="basic-info">
                  <strong class="px-2">{{$t('wgpgx_alternative_names')}}:</strong>
                  <vue-markdown :source="geneData['alternative_names']" />
                </div>
     
                <div class="basic-info">
                  <strong class="px-2">{{$t('wgpgx_alternative_symbols')}}:</strong>
                  <vue-markdown :source="geneData['alternative_symbols']" />
                </div>
  
                <vue-markdown :source="geneData['codes']" />
                <vue-markdown :source="geneData['gene']" />
              </v-card-text>
            </v-card>
          </section>
          <section
            v-for="(section, index) in sectionsWithContent"
            :key="section"
            :id="section"
          >
            <v-card class="pa-0" rounded="lg">
              <v-card-title class="solid-heading rounded-lg py-3">
                <div class="card-title white--text mb-n2">
                  {{index + 2}}. {{ $t("wgpgx_" + section) }}
                </div>
              </v-card-title>
              <v-card-text>
                <vue-markdown :source="geneData[section]" class="pa-10" />
              </v-card-text>
            </v-card>
          </section>
        </v-content>
      </v-col>

      <!-- NAVEGADOR LATERAL -->
      <v-col cols="4" md="4" lg="3">
        <v-card max-height="90vh" flat class="nav-card py-0" elevation="0">
          <a target="_blank" :href="`https://mylogygenomics.com/?p=${geneData.wp_id}`">Buy PDF</a>
          <v-navigation-drawer permanent width="100%">
            <v-list dense nav>
              <scrollactive
                active-class="active"
                :offset="80"
                :modifyUrl="false"
                :scrollOffset="100"
                :duration="800"
                bezier-easing-value=".5,0,.35,1"
                class="my-nav"
              >
                <v-list-item
                  class="ml-2 scrollactive-item"
                  link
                  href="#basic_info"
                >
                  <a> 1. {{ $t("wgpgx_basic_info") }}</a>
                </v-list-item>
                <v-list-item
                  v-for="(section, index) in sectionsWithContent"
                  :key="section"
                  class="ml-2 scrollactive-item"
                  link
                  :href="'#' + section"
                >
                  <a> {{index + 2}}. {{ $t("wgpgx_" + section) }}</a>
                </v-list-item>
              </scrollactive>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import VueMarkdown from "vue-markdown-render";

export default {
  name: "ExplorerGeneDetail",
  props: ["id"],
  components: {
    PageTopTitle,
    VueMarkdown
  },
  data() {
    return {
      geneSections: [
        "rna",
        "protein",
        "function",
        "related_diseases",
        "related_drugs",
        "animal_models",
        "allelic_variants",
        "allele_nomenclature",
        "selected_snps",
        "evolution",
        "genomics_and_pharmacogenomics",
        "drug_gene_interactions",
        "authors",
        "references"
      ],
      geneData: {
        symbol: null,
        name: null,
        alternative_names: null,
        alternative_symbols: null,
        codes: null,
        gene: null,
        rna: null,
        protein: null,
        function: null,
        related_diseases: null,
        related_drugs: null,
        animal_models: null,
        allelic_variants: null,
        allele_nomenclature: null,
        selected_snps: null,
        evolution: null,
        genomics_and_pharmacogenomics: null,
        drug_gene_interactions: null,
        authors: null,
        references: null,
        toc: null,
        wp_id: null,
        id: null
      }
    };
  },
  computed: {
    sectionsWithContent() {
      let self = this;
      return self.geneSections.filter(gs => {
        return self.geneData[gs] != null;
      });
    }
  },
  mounted() {
    this.getEPGGeneData();
  },
  methods: {
    getEPGGeneData() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/genes/${this.id}/kb?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`
      ).then(function(response) {
        response.json().then(function(data) {
          self.geneData = data.epg;
        });
      });
    }
  }
};
</script>

<style scoped>
.basic-info {
  display: flex;
}

.v-card__title {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>